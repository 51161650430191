@font-face {
  font-family: "Agricola";
  src: local("Agricola"), url(./fonts/QOAgricola-Raw.otf) format("opentype");
}
button,
.launchGame,
h1,
h2,
h3,
h4,
h5,
h6,
table,
.table,
p,
th,
td {
  font-family: "Agricola" !important;
}
button:hover,
.launchGame:hover {
  cursor: pointer !important;
}

.launchGame {
  background: #01314a !important;
}

.launchGame:hover {
  background: #2273b0 !important;
}

.App {
  .sizeWarning {
    position: fixed;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999;
    width: 100%;
    height: 100%;
    padding-top: 40px;
  }
  .background {
    width: 100%;
    position: fixed;
    z-index: -99;
    opacity: 0.7;
  }

  .worker {
    img {
      width: 20px;
      filter: drop-shadow(2px 2px 2px black);
    }
  }

  text-align: center;
  font-family: "Agricola";
  display: flex;
  .mainNav {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    width: 150px !important;
    .logo {
      margin-top: 5px;
      margin-bottom: 10px;
      padding: 10px;
      background: #01314a;
      border-radius: 5px;
    }
    button {
      background: #01314a;
      color: #fff;
      font-family: "Agricola";
      text-transform: none;
      font-size: 14px;
      margin-bottom: 10px;
      &:hover {
        background-color: #2273b0;
        color: #fff;
        cursor: pointer;
      }
    }
    .inventory {
      margin-top: 15px;
      .crops,
      .animals,
      .building {
        border-bottom: solid 1px rgb(172, 172, 172);
        padding-bottom: 5px;
      }
      .inventoryItem {
        width: 100%;
        display: flex;
        justify-content: space-between;
        filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.8));

        .modifyButtonGroup {
          button {
            background-color: #fff;
            color: rgb(117, 117, 117);
            border-color: rgb(117, 117, 117);
            &:hover {
              background: #2273b0;
              color: #fff;
              cursor: pointer;
            }
            svg {
              font-size: 10px;
              padding: 5px 2px;
            }
          }
        }

        .invIcon {
          height: 30px;
        }
        .hithere {
          animation: hithere 1s ease;
        }
        @keyframes hithere {
          30% {
            transform: scale(1.2);
          }
          40%,
          60% {
            transform: rotate(-20deg) scale(1.2);
          }
          50% {
            transform: rotate(20deg) scale(1.2);
          }
          70% {
            transform: rotate(0deg) scale(1.2);
          }
          100% {
            transform: scale(1);
          }
        }
        .barley {
          .MuiBadge-badge,
          button:hover {
            background-color: #7f391a;
          }
        }
        .flax {
          .MuiBadge-badge,
          button:hover {
            background-color: #2273b0;
          }
        }
        .wheat {
          .MuiBadge-badge,
          button:hover {
            background-color: #e0ad0d;
          }
        }
        .hops {
          .MuiBadge-badge,
          button:hover {
            background-color: #1e810e;
          }
        }
        .milk {
          .MuiBadge-badge,
          button:hover {
            background-color: #000;
          }
        }
        .meat {
          .MuiBadge-badge,
          button:hover {
            background-color: #b72763;
          }
        }
        .fur {
          .MuiBadge-badge,
          button:hover {
            background-color: #521811;
          }
        }
        .wool {
          .MuiBadge-badge,
          button:hover {
            background-color: #6e311d;
          }
        }
        .brick {
          .MuiBadge-badge,
          button:hover {
            background-color: #970610;
          }
        }
        .jewels {
          .MuiBadge-badge,
          button:hover {
            background-color: #c7a361;
          }
        }
        .tools {
          .MuiBadge-badge,
          button:hover {
            background-color: #676767;
          }
        }

        .MuiBadge-badge {
          font-family: "Agricola";
        }
      }
      .rooster {
        width: 100%;
        display: flex;
        img {
          width: 30px;
        }
        .name {
          margin-top: 12px;
          margin-left: 10px;
          background: #fff;
          padding: 10px 15px;
          border-radius: 5px;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }
  .actionBoard {
    position: absolute;
    z-index: 150;
    width: 1000px;
    height: 100%;
    right: -1000px;
    background: #fff;
    padding: 0 40px;
    transition: right 0.5s;
    filter: drop-shadow(-10px 0px 5px rgba(0, 0, 0, 0.8));
    .showActionBoard,
    .hideActionBoard {
      background: rgba(255, 255, 255, 0.3);
      position: absolute;
      z-index: 99;
      left: 5px;
      top: 5px;
      min-width: 30px !important;
      width: 30px;
      height: 30px;
      padding: 0 !important;
      &:hover {
        background: #fff;
        svg {
          color: #5f2a0e;
        }
      }
      svg {
        color: #fff;
      }
    }
    .fewerOptions {
      .fewerRemoval {
        position: absolute;
        .cardsSingle {
          .label {
            color: #fff;
            font-size: 12px;
            margin-bottom: 5px;
          }
          margin-top: 0px;
          filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4));
          img {
            width: 110px;
          }
          .controls {
            position: absolute;
            display: flex;
            justify-content: flex-start;
            bottom: 10px;
            opacity: 0;
            left: 10px;
            &:hover {
              opacity: 1;
            }
            button {
              background: #fff;
              padding: 0px;
              width: 20px;
              min-width: 20px;
              margin-right: 2px;
              &:hover {
                background: #01314a;
                svg {
                  color: #fff;
                }
              }
              &.activate svg {
                font-size: 14px;
              }
              &.delete {
                margin-left: 14px;
                &:hover {
                  background: red;
                  svg {
                    color: #fff;
                  }
                }
                svg {
                  font-size: 14px;
                  color: red;
                }
              }
            }
          }
        }
      }
      .removeButtonsGroup {
        position: absolute;
        left: 140px;
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        width: 220px;
        padding-top: 20px;
        button {
          width: 100px;
          padding: 10px 5px;
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
    }

    .clearRound {
      button {
        padding: 10px 20px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .board {
      margin-top: 20px;
      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4));
      img {
        width: 100%;
      }
    }
    .background {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      width: 100%;
      height: 100%;
    }
    .actionSquare {
      width: 150px;
      height: 140px;
      position: absolute;
      padding-left: 8px;
      padding-top: 33px;
      z-index: 2;
      &:hover {
        z-index: 3;
        .controls {
          opacity: 1;
        }
      }
      .controls {
        opacity: 0;
        z-index: 99;
        .activate {
          z-index: 99;
          position: absolute;
          top: 55px;
          left: 40px;
          background: #01314a;
          padding: 10px;
          &:hover {
            background: #fff;
            svg {
              color: #01314a;
            }
          }
          svg {
            font-size: 30px;
            color: #fff;
          }
        }
        .remove {
          right: 20px;
          &:hover {
            background: #fff;
            svg {
              color: #01314a;
            }
          }
        }
        button {
          position: absolute;
          background: #01314a;
          min-width: 10px;
          padding: 0px;
          margin-bottom: 5px;
          svg {
            color: #fff;
          }
        }
      }
      .worker {
        width: 25px;
      }
      .row-three {
        display: flex;
        gap: 7px;
        position: absolute;
      }
      .row-two {
        display: flex;
        position: absolute;
        margin-top: 39px;
        gap: 7px;
      }
      .row-one {
        display: flex;
        position: absolute;
        margin-top: 76px;
      }
      &.sheep_breeding {
        margin-left: 162px;
        margin-top: 35px;
      }
      &.clay_delivery {
        margin-left: 162px;
        margin-top: 186px;
      }
      &.small_trade {
        margin-left: 162px;
        margin-top: 355px;
      }
      &.weekly_market {
        margin-left: 162px;
        margin-top: 506px;
      }
      &.sheep_shearing {
        margin-left: 332px;
        margin-top: 35px;
      }
      &.farming {
        margin-left: 332px;
        margin-top: 186px;
      }
      &.cultivation {
        margin-left: 332px;
        margin-top: 355px;
      }
      &.land_sale {
        margin-left: 332px;
        margin-top: 506px;
      }
      &.butchery {
        margin-left: 520px;
        margin-top: 35px;
      }
      &.busy_weekend {
        margin-left: 520px;
        margin-top: 186px;
      }
      &.fertilizing {
        margin-left: 520px;
        margin-top: 355px;
      }
      &.town_hall {
        margin-left: 520px;
        margin-top: 506px;
      }
      &.nursery {
        margin-left: 689px;
        margin-top: 35px;
      }
      &.clearing {
        margin-left: 689px;
        margin-top: 186px;
      }
      &.tool_shed {
        margin-left: 689px;
        margin-top: 355px;
      }
      &.sheep_market {
        margin-left: 689px;
        margin-top: 506px;
      }
    }
    .cardSquare {
      width: 107px;
      height: 80px;
      position: absolute;
      padding-top: 6px;
      padding-left: 2px;
      &:hover {
        .controls {
          opacity: 1;
        }
      }
      &.topCard {
        .controls {
          .activate {
            top: 65px;
            left: 28px;
          }
          .takeCard {
            top: 20px;
            left: 33px;
          }
          .remove {
            right: 8px;
            top: 160px;
          }
        }
        .row-one {
          margin-top: -43px;
        }
        .row-two {
          margin-top: -80px;
        }
      }
      &.bottomCard {
        .controls {
          .activate {
            top: 150px;
            left: 28px;
          }
          .takeCard {
            top: 100px;
            left: 32px;
          }
          .remove {
            right: 8px;
            top: 6px;
          }
        }
        .row-one {
          margin-top: -194px;
        }
        .row-two {
          margin-top: -230px;
        }
      }
      .controls {
        transition: opacity 0.2s;
        width: 100px;
        height: 230px;
        opacity: 0;
        .activate {
          z-index: 99;
          position: absolute;
          background: #01314a;
          padding: 10px;
          &:hover {
            background: #fff;
            svg {
              color: #01314a;
            }
          }
          svg {
            font-size: 30px;
            color: #fff;
          }
        }
        .takeCard {
          z-index: 99;
          position: absolute;
          background: #01314a;
          padding: 10px;
          &:hover {
            background: #fff;
            svg {
              color: #01314a;
            }
          }
          svg {
            font-size: 20px;
            color: #fff;
          }
        }
        .remove {
          &:hover {
            background: #fff;
            svg {
              color: #01314a;
            }
          }
        }
        button {
          position: absolute;
          background: #01314a;
          min-width: 10px;
          padding: 0px;
          margin-bottom: 5px;
          svg {
            color: #fff;
          }
        }
      }
      .row-two {
        display: flex;
        position: absolute;
        margin-top: 39px;
        gap: 7px;
      }
      .worker {
        width: 25px;
      }
      &.gateway {
        margin-left: 38px;
        margin-top: 100px;
      }
      &.market {
        margin-left: 38px;
        margin-top: 348px;
      }
      &.farmyard {
        margin-left: 858px;
        margin-top: 100px;
      }
      &.mission {
        margin-left: 858px;
        margin-top: 348px;
      }
    }
  }
  .boards {
    flex-direction: column;
    .home {
      margin-top: 30px;
      margin-left: 10px;
      filter: drop-shadow(3px 3px 3px black);
      .buildings {
        position: absolute;
        margin-left: 456px;
        margin-top: 3px;
        text-align: left;
        .building {
          margin-bottom: 2px;
          transition: margin 0.2s;
          position: relative;
          filter: drop-shadow(3px 3px 3px black);
          &:hover .controls {
            opacity: 1;
          }
          .controls {
            opacity: 0;
            transition: opacity 0.2s;
            position: absolute;
            margin-top: 20px;
            margin-left: 3px;
            button {
              color: #b77512;
              background-color: #fff;
              border-color: #e9dece;
              &:hover {
                background-color: #e9dece;
                color: #fff;
              }
            }
          }
          .buildingImage {
            height: 55px;
          }
        }
      }
      .boulders {
        position: absolute;
        margin-left: 575px;
        margin-top: 22px;
        .boulderRow {
          height: 60px;
          .boulder {
            margin-bottom: 2px;
            transition: margin 0.2s;
            position: absolute;
            filter: drop-shadow(3px 3px 3px black);
            &:hover .controls {
              opacity: 1;
            }
            .controls {
              opacity: 0;
              transition: opacity 0.2s;
              position: absolute;
              margin-top: -10px;
              margin-left: -5px;

              button {
                color: #b77512;
                background-color: #fff;
                border-color: #e9dece;
                min-width: 10px;
                width: 10px;
                padding: 15px 8px;
                &:hover {
                  background-color: #e9dece;
                  color: #fff;
                }
                svg {
                  font-size: 10px;
                  padding: 0px;
                }
              }
            }
            .boulderImage {
              height: 20px;
            }
          }
        }
      }
      .house {
        position: absolute;
        transition: margin 0.5s;
        .workers {
          margin-top: 30px;
          margin-left: 120px;
          position: absolute;
          z-index: 120;
          width: 140px;
          padding-bottom: 50px;
          display: flex;
          flex-wrap: wrap;
          .worker {
            display: inline-block;
            margin-bottom: 5px;
          }
          &:hover {
            .workerControls {
              opacity: 1;
            }
          }
          .workerControls {
            opacity: 0;
            transition: opacity 0.2s;
            margin-bottom: 10px;
            .modifyButtonGroup {
              button {
                background: #fff;
                border-color: #081da6;
                svg {
                  font-size: 12px;
                  color: #081da6;
                }
                &:hover {
                  background: #081da6;
                  svg {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        .card {
          position: absolute;
          z-index: 99;
          margin-left: 300px;
          margin-top: 70px;
          filter: drop-shadow(3px 3px 3px black);

          &:hover {
            cursor: pointer;
            .control {
              opacity: 1;
            }
          }
          .control {
            opacity: 0;
            transition: opacity 0.2s;
            position: absolute;
            margin-left: 24px;
            margin-top: 50px;
            background: #01314a;
            border-radius: 50px;
            width: 50px;
            height: 50px;
            filter: drop-shadow(3px 3px 3px black);
            svg {
              margin-top: 7px;
              color: #fff;
              transform: rotate(180deg);
            }
          }
          img {
            width: 100px;
            height: 150px;
          }
        }
        .resetRound {
          position: absolute;
          z-index: 99;
          bottom: 10px;
          button {
            color: #1e810e;
            background-color: #fff;
            border-color: #1e810e;
            text-transform: none;
            font-size: 12px;
            &:hover {
              background-color: #1e810e;
              color: #fff;
            }
          }
        }
        .controls {
          position: absolute;
          z-index: 99;
          bottom: 10px;
          right: 5px;
          button {
            color: #1e810e;
            background-color: #fff;
            border-color: #1e810e;
            &:hover {
              background-color: #1e810e;
              color: #fff;
            }
          }
        }
        .houseImage {
          height: 300px;
          filter: drop-shadow(3px 3px 3px black);
          margin-left: -5px;
        }
      }
      .board {
        height: 300px;
      }
    }
    .farm {
      text-align: left;
      position: relative;
      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4));
      .plots {
        filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4));
        display: flex;
        position: absolute;
        margin-top: 38px;
        margin-left: 70px;
        gap: 12px;
        .farmField {
          transition: margin 0.4s, opacity 0.2s;
          position: relative;
          .plantedCrop {
            position: absolute;
            left: 10px;
            top: 7px;
            z-index: 20;
            img {
              width: 30px;
              padding: 4px;
              filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4));
            }
          }
          .field {
            height: 60px;
          }
          position: relative;
          .plantActions {
            z-index: 40;
            transition: opacity 0.1s;
            opacity: 0;
            transition: opacity 0.2s;
            position: absolute;
            margin-top: 5px;
            &:hover {
              opacity: 1;
            }
            &:hover + .plantedCrop {
              opacity: 0;
              transition: opacity 0.2s;
            }
            .plantCrops {
              display: flex;
              flex-wrap: wrap;
              padding: 0px 7px;
              justify-content: space-between;
              button.cropButton {
                width: 20px;
                padding: 2px;
                background: rgba(237, 237, 237, 0.8);
                border: solid 1px #ccc;
                border-radius: 5px;
                margin-bottom: 6px;
                &:hover {
                  background-color: #fff;
                }
                &.harvest {
                  width: 40px;
                  height: 40px;
                  margin-left: 3px;
                  margin-top: 4px;
                  img {
                    width: 20px;
                  }
                  background: rgb(141, 0, 0);
                }
                img {
                  width: 10px;
                }
                svg {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
      .controls {
        display: flex;
        gap: 13px;
        margin-left: 65px;
        .farmFieldControls {
          .modifyButtonGroup {
            width: 60px;
            height: 25px;
            button {
              background-color: #fff;
              color: rgb(117, 117, 117);
              border-color: rgb(117, 117, 117);
              &:hover {
                background: #5f2a0e;
                color: #fff;
                cursor: pointer;
              }
              min-width: 10px;
              svg {
                font-size: 10px;
              }
            }
          }
        }
      }
      .board {
        height: 400px;
      }
    }
  }
  .sheepBoard {
    position: absolute;
    margin-left: 820px;
    margin-top: 15px;
    .sheep {
      .sheepSlots {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        margin-left: 22px;
        margin-top: 20px;
        .sheepSlot {
          width: 100px;
          height: 150px;
          .sheepGroup {
            position: absolute;
            z-index: 99;
            display: flex;
            flex-wrap: wrap;
            width: 80px;
            margin-top: 40px;
            margin-left: 23px;
            .sheep {
              width: 30px;
              margin-right: 5px;
              margin-bottom: 5px;
              filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4));
            }
          }
          .controls {
            position: absolute;
            margin-left: 0px;
            margin-top: 10px;
            z-index: 99;
            &:hover .modifyButtonGroup {
              opacity: 1;
            }
            width: 100px;
            text-align: left;
            .modifyButtonGroup {
              opacity: 0;
              flex-direction: column;
              transition: opacity 0.2s;
              .transfer {
                margin-bottom: 60px;
                border-color: #396bc2;
                svg {
                  transform: rotate(180deg);
                  color: #396bc2;
                }
                &:hover {
                  background: #396bc2;
                  border: #396bc2;
                  svg {
                    color: #fff;
                  }
                }
                &:disabled {
                  opacity: 0.5;
                }
              }
              button {
                background: #fff;
                padding: 5px !important;
                min-width: 20px;
                border-radius: 4px;
                margin: 0 0 2px 0;
                border: #526c25;
                svg {
                  padding: 0px;
                  color: #526c25;
                  font-size: 14px;
                }
                &:hover {
                  background: #526c25;
                  border: #526c25;
                  svg {
                    color: #fff;
                  }
                }
              }
            }
          }
          .card {
            width: 100px;
            height: 150px;
            filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4));
          }
        }
      }
      .stable {
        position: absolute;
        margin-top: 355px;
        padding-left: 10px;
        width: 370px;
        height: 70px;
        &:hover .controls {
          opacity: 1;
        }
        .controls {
          opacity: 0;
          transition: opacity 0.2s;
          position: absolute;
          margin-left: 0px;
          margin-top: 10px;
          z-index: 99;
          .modifyButtonGroup {
            &:hover button {
              opacity: 1;
            }
            flex-direction: column;
            .transfer {
              margin-bottom: 60px;
              border-color: #396bc2;
              svg {
                transform: rotate(180deg);
                color: #396bc2;
              }
              &:hover {
                background: #396bc2;
                border: #396bc2;
                svg {
                  color: #fff;
                }
              }
            }
            button {
              opacity: 1;
              transition: opacity 0.2s;
              background: #fff;
              padding: 5px !important;
              min-width: 20px;
              border-radius: 4px;
              margin: 0 0 2px 0;
              border-color: #526c25;
              svg {
                padding: 0px;
                color: #526c25;
                font-size: 14px;
              }
              &:hover {
                background: #526c25;
                border: #526c25;
                svg {
                  color: #fff;
                }
              }
            }
          }
        }
        .sheepGroup {
          position: absolute;
          z-index: 99;
          display: flex;
          flex-wrap: wrap;
          width: 340px;
          margin-top: 20px;
          margin-left: 40px;
          .sheep {
            width: 30px;
            margin-right: 5px;
            margin-bottom: 5px;
            filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4));
          }
        }
      }
    }
    filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4));
    .board {
      height: 430px;
    }
  }
  .cardsContainer {
    margin-left: 100px;
    margin-top: 18px;
    display: flex;
    .unplayedCards {
      display: flex;
      width: 250px;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
      overflow-y: scroll;
      height: calc(100vh - 68px);
      position: relative;
      padding-bottom: 50px;
    }

    .usedCards {
      display: flex;
      width: 120px;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
      overflow-y: scroll;
      height: calc(100vh - 68px);
      position: relative;
      padding-bottom: 50px;
      margin-left: 10px;
      padding: 10px;
      background: rgba(0, 0, 0, 0.6);
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.9);
      border-radius: 10px;
      .header {
        background: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: bold;
      }
    }
    .cardsSingle {
      margin-bottom: 5px;
      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4));
      img {
        width: 120px;
      }
      .controls {
        position: absolute;
        display: flex;
        justify-content: flex-start;
        bottom: 10px;
        opacity: 0;
        left: 10px;
        &:hover {
          opacity: 1;
        }
        button {
          background: #fff;
          padding: 0px;
          width: 20px;
          min-width: 20px;
          margin-right: 2px;
          &:hover {
            background: #01314a;
            svg {
              color: #fff;
            }
          }
          &.activate svg {
            font-size: 14px;
          }
          &.delete {
            margin-left: 14px;
            &:hover {
              background: red;
              svg {
                color: #fff;
              }
            }
            svg {
              font-size: 14px;
              color: red;
            }
          }
        }
      }
    }
  }
  .playOrder {
    position: absolute;
    bottom: 30px;
    left: 180px;
    display: flex;
    width: 400px;
    flex-wrap: wrap;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px;
    border-radius: 5px;
    .left,
    .right {
      text-align: left;
    }
    .left {
      margin-right: 20px;
    }
    .step {
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }
}

.newGameModal {
  border-radius: 5px;
  h2 {
    margin-bottom: 20px;
  }
  .newGameForm {
    .gameName {
      margin-bottom: 10px;
    }
    .players {
      .playerInput {
        margin-right: 10px;
      }
      margin-bottom: 10px;
    }
    .gameType {
      display: flex;
      .gateway {
        margin-right: 20px;
      }
      button {
        position: absolute;
        right: 20px;
        bottom: 20px;
        font-family: "Agricola";
        background-color: #01314a;
        &:hover {
          background-color: #2273b0;
        }
      }
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate {
  animation-duration: 0.75s;
  animation-delay: 0s;
  animation-name: animate-splat;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

.animate.drop {
  animation-name: animate-drop;
  animation-timing-function: cubic-bezier(0.77, 0.14, 0.91, 1.25);
}
@keyframes animate-drop {
  0% {
    opacity: 0;
    transform: translate(0, -300px) scale(0.9, 1.1);
  }
  95% {
    opacity: 1;
    transform: translate(0, 0) scale(0.9, 1.1);
  }
  96% {
    opacity: 1;
    transform: translate(10px, 0) scale(1.2, 0.9);
  }
  97% {
    opacity: 1;
    transform: translate(-10px, 0) scale(1.2, 0.9);
  }
  98% {
    opacity: 1;
    transform: translate(5px, 0) scale(1.1, 0.9);
  }
  99% {
    opacity: 1;
    transform: translate(-5px, 0) scale(1.1, 0.9);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0) scale(1, 1);
  }
}

.animate.splat {
  animation-name: animate-splat;
}

@keyframes animate-splat {
  0% {
    opacity: 0;
    transform: scale(0, 0) rotate(20deg) translate(0, -30px);
  }
  70% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(15deg);
  }
  85% {
    opacity: 1;
    transform: scale(1.1, 1.1) rotate(15deg) translate(0, -10px);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1) rotate(0) translate(0, 0);
  }
}
